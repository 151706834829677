import React from 'react';
import cn from 'classnames';

import styles from './icon.module.scss';

interface Props {
  className?: string;
}

export function CaretIcon(props: Props) {
  return (
    <div className={cn(styles.caretIcon, props.className)}>
      <svg fill="none" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 1L5 5L1 1" />
      </svg>
    </div>
  );
}
