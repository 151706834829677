import React from 'react';
import cn from 'classnames';

import { range } from '../helpers';

import styles from './carousel.module.scss';

interface Props {
  children: React.ReactNode[];
  duration?: number;
  autoplay?: boolean;
  timeout?: number;
  infinite?: boolean;
  items?: number;
  center?: boolean;
  fullWidth?: boolean;
  waitForLoad?: boolean;
  className?: string;
  showDots?: boolean;
  dotsClassName?: string;
  dotClassName?: string;
  showControls?: boolean;
  controlClassName?: string;
  controlPrevClassName?: string;
  controlNextClassName?: string;
  onDotClick?(index: number): void;
  onControlClick?(): void;
}

interface State {
  loading: boolean;
  itemsPerPage: number;
  active: number;
  offset: number;
  overMaxOffset: boolean;
}

function promisifySetState($this, state) {
  return new Promise<void>(resolve => {
    $this.setState(state, () => resolve());
  });
}

export default class Carousel extends React.PureComponent<Props, State> {
  public static defaultProps = {
    duration: 600,
    autoplay: false,
    timeout: 5000,
    items: 3,
    center: true,
    waitForLoad: false,
    fullWidth: false,
  };

  state = {
    loading: true,
    itemsPerPage: this.props.fullWidth ? 1 : this.props.items,
    active: 0,
    offset: 0,
    overMaxOffset: false,
  };

  private containerRef = React.createRef<HTMLDivElement>();

  private get children() {
    return React.Children.toArray(this.props.children);
  }

  private interval = null;
  private paused = false;
  private touchStart = 0;
  private dx = 0;
  private maxOffset: number;
  private slideTreshold = 100;

  private get itemsBeforeActive() {
    if (this.props.fullWidth) return 0;
    if (!this.props.center) return 0;
    if (this.state.itemsPerPage < 2) return 0;
    return Math.round(this.state.itemsPerPage / 2);
  }

  private get next(): number {
    return this.state.active + 1 >= this.children.length ? this.state.active : this.state.active + 1;
  }

  private get nextRotate(): number {
    return this.state.active + 1 >= this.children.length ? 0 : this.state.active + 1;
  }

  private get prev(): number {
    return this.state.active - 1 < 0 ? 0 : this.state.active - 1;
  }

  private get prevRotate(): number {
    return this.state.active - 1 < 0 ? this.children.length - 1 : this.state.active - 1;
  }

  private computeOffset(active: number): number {
    const activeChildIndex = active + this.itemsBeforeActive;
    let offset = 0;
    for (let index = 0; index < activeChildIndex; index++) {
      const child = this.containerRef.current.children[index];
      offset += child.clientWidth;
    }
    if (offset > this.maxOffset) {
      offset = this.maxOffset;
    }
    if (!this.props.center || this.props.fullWidth) return offset;

    const activeChild = this.containerRef.current.children[activeChildIndex];
    return offset - (this.containerRef.current.clientWidth - activeChild.clientWidth) / 2;
  }

  private computeItemsPerPage() {
    if (this.props.fullWidth) return 1;
    let itemsPerPage = this.itemsBeforeActive;
    let width = 0;
    for (; itemsPerPage < this.containerRef.current.children.length; itemsPerPage++) {
      const child = this.containerRef.current.children[itemsPerPage];
      width += child.clientWidth;
      if (width >= this.containerRef.current.clientWidth) {
        break;
      }
    }
    return itemsPerPage - this.itemsBeforeActive + 1;
  }

  private load = async () => {
    if (!this.containerRef.current) return;
    if (!this.state.loading) {
      await promisifySetState(this, { loading: true });
    }

    const containerWidth = this.containerRef.current.clientWidth;
    const maxWidth = this.containerRef.current.scrollWidth;
    this.maxOffset = maxWidth - containerWidth;
    this.slideTreshold = this.containerRef.current.clientWidth * 0.4;
    const itemsPerPage = this.computeItemsPerPage();
    await promisifySetState(this, { itemsPerPage });

    const offset = this.computeOffset(this.state.active);
    await promisifySetState(this, { offset });

    setTimeout(() => {
      this.setState({ loading: false });
    }, this.props.duration);

    if (this.props.autoplay && !this.interval) {
      this.interval = setTimeout(this.animate, this.props.timeout + this.props.duration);
    }
  };

  private animate = () => {
    this.interval = setTimeout(this.animate, this.props.timeout + this.props.duration);
    if (this.paused) return;
    this.slide(this.nextRotate);
  };

  private slide(next: number) {
    if (this.state.active === next) return;
    const offset = this.computeOffset(next);
    this.setState({
      offset: offset,
      active: next,
      overMaxOffset: offset === this.maxOffset,
    });
  }

  private init() {
    Promise.resolve()
      .then(() => {
        if (!this.props.waitForLoad) return;
        const images = this.containerRef.current?.getElementsByTagName('img');
        if (!images) return;
        return Promise.all(
          Array.from(images).map(
            image =>
              new Promise<void>(resolve => {
                const handleImageLoad = () => {
                  resolve();
                  image.removeEventListener('load', handleImageLoad);
                  image.removeEventListener('error', handleImageLoad);
                };
                image.addEventListener('load', handleImageLoad);
                image.addEventListener('error', handleImageLoad);
              }),
          ),
        );
      })
      .then(() => this.load());
  }

  private cleanup() {
    if (this.interval) {
      clearTimeout(this.interval);
    }
  }

  private handleClickPrev = () => {
    this.props.onControlClick?.();
    this.slide(this.props.infinite ? this.prevRotate : this.prev);
  };

  private handleClickNext = () => {
    this.props.onControlClick?.();
    this.slide(this.props.infinite ? this.nextRotate : this.next);
  };

  private handleMouseEnter = () => {
    this.paused = true;
  };

  private handleMouseOut = () => {
    this.paused = false;
  };

  private handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    this.paused = true;
    this.touchStart = event.touches[0]?.clientX ?? 0;
  };

  private handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    this.paused = true;
    this.dx = event.touches[0]?.clientX - this.touchStart;
  };

  private handleTouchEnd = () => {
    if (Math.abs(this.dx) > this.slideTreshold) {
      if (this.dx > 0) {
        this.handleClickPrev();
      } else {
        this.handleClickNext();
      }
    }
    this.dx = 0;
    this.paused = false;
  };

  private handleDotClick = (index: number) => () => {
    this.props.onDotClick?.(index);
    this.slide(index);
  };

  public componentDidMount() {
    window.addEventListener('resize', this.load, { passive: true });
    this.init();
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.load);
    this.cleanup();
  }

  public render() {
    return (
      <div
        className={cn(styles.carousel, { [styles.loading]: this.state.loading }, this.props.className)}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseOut}
        onTouchEnd={this.handleTouchEnd}
        onTouchMove={this.handleTouchMove}
        onTouchStart={this.handleTouchStart}
      >
        <div className={styles.carouselContainer}>
          <div
            className={styles.carouselInner}
            ref={this.containerRef}
            style={{
              transform: `translateX(${-this.state.offset}px)`,
              transitionDuration: `${this.props.duration}ms`,
            }}
          >
            {range(0, this.itemsBeforeActive)
              .reverse()
              .map(i => (
                <div className={styles.carouselItem} key={`carousel-item-before-${i}`}>
                  {this.children[this.children.length - i - 1]}
                </div>
              ))}

            {this.children.map((child, index) => (
              <div
                className={cn(styles.carouselItem, {
                  active: index === this.state.active,
                  'full-width': this.props.fullWidth,
                })}
                key={`carousel-item-${index}`}
              >
                {child}
              </div>
            ))}

            {range(0, this.itemsBeforeActive).map(i => (
              <div className={styles.carouselItem} key={`carousel-item-after-${i}`}>
                {this.children[i]}
              </div>
            ))}
          </div>
        </div>
        {this.props.showDots ? (
          <div className={this.props.dotsClassName ?? styles.carouselDots}>
            {this.children.map((_, index) => (
              <div
                className={cn(this.props.dotClassName ?? styles.dot, {
                  active: this.state.active === index,
                })}
                key={index}
                onClick={this.handleDotClick(index)}
                role="button"
              />
            ))}
          </div>
        ) : null}
        {this.props.showControls && this.state.active > 0 ? (
          <a
            className={cn(
              this.props.controlClassName ?? styles.carouselControl,
              this.props.controlPrevClassName ?? styles.carouselControlPrev,
            )}
            onClick={this.handleClickPrev}
            role="button"
          >
            <i className="icon icon-arrow-left" />
          </a>
        ) : null}
        {this.props.showControls && this.state.active < this.children.length - 1 ? (
          <a
            className={cn(
              this.props.controlClassName ?? styles.carouselControl,
              this.props.controlNextClassName ?? styles.carouselControlNext,
            )}
            onClick={this.handleClickNext}
            role="button"
          >
            <i className="icon icon-arrow-right" />
          </a>
        ) : null}
      </div>
    );
  }
}
