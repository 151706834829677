import React from 'react';

import { StaticImage } from '../Image';
import MobileAppLinks from '../MobileAppLinks';
import T from '../Translate';

import styles from './mobileAppPromo.module.scss';

export default function MobileAppPromo() {
  return (
    <div className={styles.container}>
      <T as="h2" className={styles.title} html id="mobileAppPromo.title" />
      <T as="p" id="mobileAppPromo.description" />
      <MobileAppLinks className={styles.links} loading="lazy" />
      <StaticImage className={styles.image} loading="lazy" name="hp_mobile" />
    </div>
  );
}
