import React, { useRef } from 'react';
import cn from 'classnames';

import { useOutsideClick } from '../../hooks/useOutsideClick';
import { CaretIcon } from '../Icons/CaretIcon';
import Translate from '../Translate';

import styles from './Select.module.scss';

export interface SelectOption<T extends string> {
  key?: string;
  value: T;
  label: string | React.ReactNode;
  filterValue?: string;
}

export interface SelectProps<T extends string, Option extends SelectOption<T> = SelectOption<T>> {
  value: T;
  options?: Option[];
  label?: string | React.ReactNode;
  error?: string | React.ReactNode | boolean | null | undefined;
  hint?: string | React.ReactNode;
  disabled?: boolean;
  className?: string;

  onChange(value: T): void | Promise<void>;
}

export function Select<T extends string>(props: SelectProps<T>) {
  const ref = useRef<HTMLLabelElement>(null);
  const [opened, setOpened] = React.useState(false);
  const { options = [], label, error, hint, disabled } = props;

  useOutsideClick(() => setOpened(false), ref);

  const handleSelect = (option: SelectOption<T>) => {
    props.onChange(option.value);
    setOpened(false);
  };

  return (
    <label
      className={cn(styles.select, props.className, {
        disabled,
        error: !!error,
      })}
      ref={ref}
    >
      {label && (
        <span className={styles.label}>{typeof label === 'string' ? <Translate id={label} optional /> : label}</span>
      )}
      <div className={cn(styles.input, { opened })}>
        <div className={styles.value} onClick={() => setOpened(!opened)}>
          {options.find(it => it.value === props.value)?.label}
        </div>
        <CaretIcon className={styles.icon} />
        <div className={styles.dropdown}>
          {options.map(option => (
            <div className={styles.option} key={option.key ?? option.value} onClick={() => handleSelect(option)}>
              {option.label}
            </div>
          ))}
        </div>
      </div>
      {error && error !== true ? (
        <span className={styles.error}>{typeof error === 'string' ? <Translate id={error} optional /> : error}</span>
      ) : hint ? (
        <span className={styles.hint}>{typeof hint === 'string' ? <Translate id={hint} optional /> : hint}</span>
      ) : null}
    </label>
  );
}

export interface SelectWithFilterOption<T extends string> extends SelectOption<T> {
  filterValue: string;
}

export interface SelectWithFilterProps<T extends string> extends SelectProps<T, SelectWithFilterOption<T>> {
  id?: string;
  autoComplete?: React.InputHTMLAttributes<HTMLInputElement>['autoComplete'];
  children?: (option: SelectWithFilterOption<T> | undefined, filterInput: React.ReactNode) => React.ReactNode;
}

export function SelectWithFilter<T extends string>(props: SelectWithFilterProps<T>) {
  const ref = useRef<HTMLDivElement>(null);
  const id = useRef(props.id ?? Math.random().toString(36).substring(2, 9));
  const [opened, setOpened] = React.useState(false);
  const [state, setState] = React.useState({
    filter: props.options.find(it => it.value === props.value)?.filterValue ?? '',
    touched: false,
  });
  const { options = [], label, error, hint, disabled } = props;

  const filteredOptions = React.useMemo(() => {
    if (state.filter === '' || !state.touched) return options;
    return options.filter(option => option.filterValue.toLowerCase().includes(state.filter.toLowerCase()));
  }, [state, options]);

  useOutsideClick(() => setOpened(false), ref);

  const handleSelect = (option: SelectWithFilterOption<T>) => {
    setState({ filter: option.filterValue, touched: false });
    props.onChange(option.value);
    setOpened(false);
  };

  const handleFilter = (value: string) => {
    setState({ filter: value, touched: true });
  };

  const Filter = (
    <input
      autoComplete={props.autoComplete}
      className={styles.filter}
      disabled={disabled}
      id={id.current}
      onChange={e => handleFilter(e.target.value as T)}
      onFocus={() => setOpened(true)}
      type="text"
      value={state.filter}
    />
  );

  return (
    <div
      className={cn(styles.select, props.className, {
        disabled,
        error: !!error,
      })}
      ref={ref}
    >
      {label && (
        <label className={styles.label} htmlFor={id.current}>
          {typeof label === 'string' ? <Translate id={label} optional /> : label}
        </label>
      )}
      <div className={cn(styles.input, { opened })}>
        {props.children
          ? props.children(
              options.find(it => it.value === props.value),
              Filter,
            )
          : Filter}
        <CaretIcon className={styles.icon} />
        <div className={styles.dropdown}>
          {filteredOptions.map(option => (
            <div className={styles.option} key={option.key ?? option.value} onClick={() => handleSelect(option)}>
              {option.label}
            </div>
          ))}
        </div>
      </div>
      {error && error !== true ? (
        <span className={styles.error}>{typeof error === 'string' ? <Translate id={error} optional /> : error}</span>
      ) : hint ? (
        <span className={styles.hint}>{typeof hint === 'string' ? <Translate id={hint} optional /> : hint}</span>
      ) : null}
    </div>
  );
}
