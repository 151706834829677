import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { useEnum } from '../../hooks/useEnum';
import { countryToCurrency, type CurrencyCode, type PriceType } from '../../model/Price';
import type { Country } from '../../services/Country';
import {
  CalculatorType,
  type InitialValues,
  VehicleEarningsCalculator,
  type VehicleExpenses,
  type VehicleExpensesCalculationData,
} from '../../services/VehicleEarningsCalculator';
import { useRegion } from '../Restriction';

export interface CalculatorState {
  type: CalculatorType;
  vehicleMarketPrice: number;
  freeDays: number;
  vehicleManufactureYear: number;
  vehicleYearlyMileage: number;
}

export interface VehicleEarningsContext {
  readonly region: Country;
  readonly currency: CurrencyCode;
  readonly initialValues: InitialValues;
  readonly state: CalculatorState;

  setState(state: Partial<CalculatorState>);
}

const earningsContext = React.createContext<VehicleEarningsContext>(null);

export interface SimpleCalculatorChange {
  calculatorType: CalculatorType;
  vehicleMarketPrice: PriceType;
  freeDays: number;
}

export interface EarningsProviderProps {
  onChange?(state: SimpleCalculatorChange): void;
}

export function useCalculatorService(): VehicleEarningsCalculator {
  const data = useStaticQuery<VehicleExpensesCalculationData>(graphql`
    query {
      priceTiers: allPriceTiersTsv {
        group(field: country) {
          fieldValue
          group(field: age_tier) {
            fieldValue
            nodes {
              tier_id
              min
              max
            }
          }
        }
      }
      ageTiers: allAgeAndMileageTiersTsv(filter: { tier_type: { eq: "age_tier" } }) {
        group(field: country) {
          fieldValue
          nodes {
            tier_id
            min
            max
          }
        }
      }
      mileageTiers: allAgeAndMileageTiersTsv(filter: { tier_type: { eq: "mileage_tier" } }) {
        group(field: country) {
          fieldValue
          nodes {
            tier_id
            min
            max
          }
        }
      }
      costs: allCostsTsv {
        group(field: country) {
          fieldValue
          nodes {
            price_tier
            mileage_tier
            age_tier
            depreciation_costs
            insurance_costs
            maintenance_costs
            repairs_costs
            other_costs
            administrative_costs
          }
        }
      }
      fuelCosts: allFuelCostsTsv {
        group(field: country) {
          fieldValue
          nodes {
            currency
            amount
          }
        }
      }
    }
  `);
  const service = useRef(new VehicleEarningsCalculator(data));

  useEffect(() => {
    service.current = new VehicleEarningsCalculator(data);
  }, [data]);

  return service.current;
}

export function usePricePerDayCoefficient(): number | undefined {
  const [configs] = useEnum('config');
  const pricePerDayCoefficient = configs?.find(c => c.trans_code === 'price_per_day_coefficient')?.value;
  return pricePerDayCoefficient && Number.parseFloat(pricePerDayCoefficient);
}

export function VehicleEarningsCalculatorProvider(props: React.PropsWithChildren<EarningsProviderProps>) {
  const [region] = useRegion();
  const currency = useMemo(() => countryToCurrency(region), [region]);
  const service = useCalculatorService();
  const [state, setState] = useState<CalculatorState>(() => {
    const initialValues = service.findInitialValues(region, CalculatorType.SIMPLE);
    return {
      type: CalculatorType.SIMPLE,
      vehicleMarketPrice: initialValues.vehiclePrice,
      freeDays: 5,
      vehicleManufactureYear: initialValues.vehicleManufactureYear,
      vehicleYearlyMileage: initialValues.vehicleYearlyMileage,
    };
  });

  const ctx: VehicleEarningsContext = {
    region,
    currency,
    state,
    initialValues: service.findInitialValues(region, state.type, state.vehicleManufactureYear),
    setState(changes) {
      const nextState: Partial<CalculatorState> = { ...state, ...changes };
      setState(prev => ({ ...prev, ...nextState }));
      props.onChange?.({
        calculatorType: nextState.type,
        vehicleMarketPrice: { amount: nextState.vehicleMarketPrice, currency },
        freeDays: nextState.freeDays,
      });
    },
  };

  useEffect(() => {
    const initialValues = service.findInitialValues(region, state.type, state.vehicleManufactureYear);
    const nextState = {
      vehicleMarketPrice: initialValues.vehiclePrice,
    };
    setState(prev => ({ ...prev, ...nextState }));
  }, [region]);

  return <earningsContext.Provider value={ctx}>{props.children}</earningsContext.Provider>;
}

export function useCalculatorContext() {
  return useContext(earningsContext);
}

export function useVehicleEarnings() {
  const coefficient = usePricePerDayCoefficient();
  const service = useCalculatorService();
  const ctx = useCalculatorContext();

  return service.calculateVehicleEarnings({
    pricePerDayCoefficient: coefficient,
    vehicleMarketPrice: ctx.state.vehicleMarketPrice,
    vehicleFreeDays: ctx.state.freeDays,
    currency: ctx.currency,
    country: ctx.region,
  });
}

export interface VehicleExpensesContext {
  state: VehicleExpenses;
  setState: (changes: Partial<VehicleExpenses>) => void;
}

const expensesContext = createContext<VehicleExpensesContext>(null);

export function VehicleExpensesCalculatorProvider(props: React.PropsWithChildren<UnsafeAny>) {
  const service = useCalculatorService();
  const ctx = useCalculatorContext();
  const [state, setState] = useState<VehicleExpenses>(() =>
    service.findVehicleExpenses({
      vehicleMarketPrice: ctx.state.vehicleMarketPrice,
      vehicleAge: VehicleEarningsCalculator.manufactureYearToAge(ctx.state.vehicleManufactureYear),
      vehicleYearlyMileage: ctx.state.vehicleYearlyMileage,
      currency: ctx.currency,
      country: ctx.region,
    }),
  );

  useEffect(() => {
    const vehicleExpenses = service.findVehicleExpenses({
      vehicleMarketPrice: ctx.state.vehicleMarketPrice,
      vehicleAge: VehicleEarningsCalculator.manufactureYearToAge(ctx.state.vehicleManufactureYear),
      vehicleYearlyMileage: ctx.state.vehicleYearlyMileage,
      currency: ctx.currency,
      country: ctx.region,
    });
    setState(prev => ({ ...prev, ...vehicleExpenses }));
  }, [ctx.state.vehicleMarketPrice, ctx.state.vehicleManufactureYear, ctx.state.vehicleYearlyMileage, ctx.region]);

  const handleChange = (changes: Partial<VehicleExpenses>) => {
    setState(prev => ({ ...prev, ...changes }));
  };

  return (
    <expensesContext.Provider
      value={{
        state,
        setState: handleChange,
      }}
    >
      {props.children}
    </expensesContext.Provider>
  );
}

export function useVehicleExpensesContext() {
  return useContext(expensesContext);
}

export function useVehicleExpenses() {
  const service = useCalculatorService();
  const ctx = useContext(earningsContext);
  const expenses = useContext(expensesContext);

  return service.calculateVehicleExpenses({
    vehicleMarketPrice: ctx.state.vehicleMarketPrice,
    vehicleAge: VehicleEarningsCalculator.manufactureYearToAge(ctx.state.vehicleManufactureYear),
    vehicleYearlyMileage: ctx.state.vehicleYearlyMileage,
    currency: ctx.currency,
    country: ctx.region,
    administrativeCosts: expenses.state.administrativeCosts,
    depreciationCosts: expenses.state.depreciationCosts,
    fuelCosts: expenses.state.fuelCosts,
    insuranceCosts: expenses.state.insuranceCosts,
    maintenanceCosts: expenses.state.maintenanceCosts,
    otherCosts: expenses.state.otherCosts,
    repairsCosts: expenses.state.repairsCosts,
  });
}
