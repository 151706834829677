import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { number } from 'yup';

import { range } from '../../helpers';
import RouteEnum from '../../RouteEnum';
import { getYear } from '../../services/DateTime';
import Tracking from '../../services/Tracking';
import { CalculatorType } from '../../services/VehicleEarningsCalculator';
import Button from '../Button';
import { Slider, type SliderProps } from '../Form/Slider';
import { TextInput } from '../Input/Input';
import { Select } from '../Input/Select';
import { useEditingField, useValidationSchema } from '../Input/useField';
import Link from '../Link';
import NumberFormat from '../NumberFormat';
import Price from '../Price';
import T, { TContext } from '../Translate';

import {
  useCalculatorContext,
  useCalculatorService,
  useVehicleEarnings,
  useVehicleExpenses,
  useVehicleExpensesContext,
  VehicleExpensesCalculatorProvider,
} from './VehicleCalculatorContext';

import styles from './EarningsCalculator.module.scss';

interface Props {
  onFormShrink?: () => void;
  onFormExpand?: () => void;
}

interface CalculatorTemplateProps {
  results: React.ReactNode;
  form?: React.ReactNode;
}

function CalculatorTemplate({ results, form }: CalculatorTemplateProps, ref) {
  const ctx = useCalculatorContext();
  const isAdvanced = ctx.state.type === CalculatorType.ADVANCED;

  return (
    <TContext name={ctx.state.type}>
      <div className={cn(styles.calculatorSection, { advanced: isAdvanced })} ref={ref}>
        <div className={styles.calculatorForm}>
          <div className={styles.calculatorTabs}>
            <button
              className={cn(styles.tab, {
                active: ctx.state.type === CalculatorType.SIMPLE,
              })}
              onClick={() => ctx.setState({ type: CalculatorType.SIMPLE })}
              type="button"
            >
              <T id="calculator.tabs.simple" />
            </button>
            <button
              className={cn(styles.tab, {
                active: ctx.state.type === CalculatorType.ADVANCED,
              })}
              onClick={() => ctx.setState({ type: CalculatorType.ADVANCED })}
              type="button"
            >
              <T id="calculator.tabs.advanced" />
            </button>
          </div>

          <div className={styles.form}>
            <div className={styles.header}>
              <T as="h2" className={styles.title} id="calculator.title" multiline />
              {isAdvanced ? <T as="p" className={styles.description} id="calculator.description" multiline /> : null}
            </div>

            <div className={styles.slider}>
              <label className={styles.label}>
                <T as="span" id="calculator.vehicleMarketPrice.label" />
                <Price amount={ctx.state.vehicleMarketPrice} className={styles.value} currency={ctx.currency} />
              </label>
              <CalculatorSlider
                max={ctx.initialValues.vehiclePrice_max}
                min={ctx.initialValues.vehiclePrice_min}
                onChange={value => ctx.setState({ vehicleMarketPrice: value })}
                step={ctx.initialValues.vehiclePrice_step}
                value={ctx.state.vehicleMarketPrice}
              />
            </div>

            {form}

            <div className={styles.slider}>
              <label className={styles.label}>
                <T as="span" html id="calculator.freeDays.label" />
                <T className={styles.value} data={{ count: ctx.state.freeDays }} id="calculator.freeDays.value" />
              </label>
              <CalculatorSlider
                max={7}
                min={1}
                onChange={value => ctx.setState({ freeDays: value })}
                value={ctx.state.freeDays}
              />
            </div>
          </div>
        </div>
        <aside className={styles.aside}>
          <div className={styles.calculatorEarnings}>
            {results}
            <Link
              className="button button-primary button-lg button-block button-icon"
              onClick={() => Tracking.track('OFFER_CAR_ADD_CAR_CLICKED')}
              privatePath
              to={RouteEnum.ADD_CAR}
            >
              <T id="calculator.earnings.link" />
              <i className="icon icon-arrow-right-circle" />
            </Link>
          </div>
        </aside>
      </div>
    </TContext>
  );
}

const Calculator = React.forwardRef<HTMLDivElement, CalculatorTemplateProps>(CalculatorTemplate);

function SimpleCalculator() {
  return <Calculator results={<SimpleCalculatorResults />} />;
}

function AdvancedCalculator(props: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const handleShrinkForm = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    props.onFormShrink?.();
  };

  return (
    <VehicleExpensesCalculatorProvider>
      <Calculator
        form={<AdvancedCalculatorForm onFormExpand={props.onFormExpand} onFormShrink={handleShrinkForm} />}
        ref={ref}
        results={<AdvancedCalculatorResults />}
      />
    </VehicleExpensesCalculatorProvider>
  );
}

export default function EarningsCalculator(props: Props) {
  const ctx = useCalculatorContext();

  return ctx.state.type === CalculatorType.SIMPLE ? <SimpleCalculator /> : <AdvancedCalculator {...props} />;
}

const CalculatorSlider = (props: Omit<SliderProps, 'styleOverwrite'>) => (
  <Slider
    {...props}
    styleOverwrite={{
      trackStyle: {
        backgroundColor: '#fff',
      },
      railStyle: {
        backgroundColor: '#fff',
      },
      handleStyle: {
        borderColor: '#fff',
      },
    }}
  />
);

function AdvancedCalculatorForm({ onFormShrink, onFormExpand }: Props) {
  const ctx = useCalculatorContext();
  const expenses = useVehicleExpensesContext();
  const [expanded, setExpanded] = useState(false);
  const hintCtx = ctx.region.toLowerCase();
  const vehicleYearlyMileageValidation = useValidationSchema<number>(
    number()
      .min(ctx.initialValues.vehicleYearlyMileageMin, 'global.validation.min')
      .max(ctx.initialValues.vehicleYearlyMileageMax, 'global.validation.max'),
  );
  const [vehicleYearlyMileage, setVehicleYearlyMileage] = useEditingField(
    'vehicleYearlyMileage',
    ctx.state.vehicleYearlyMileage,
    vehicleYearlyMileageValidation,
    value => ctx.setState({ vehicleYearlyMileage: value }),
  );

  const handleExpandForm = () => {
    setExpanded(true);
    onFormExpand?.();
  };

  const handleShrinkForm = () => {
    setExpanded(false);
    onFormShrink?.();
  };

  return (
    <div className={cn(styles.advancedForm, { expanded })}>
      <Select
        className="on-dark-bg"
        label={<T id="calculator.vehicleManufactureYear.label" />}
        onChange={year => ctx.setState({ vehicleManufactureYear: Number(year) })}
        options={range(ctx.initialValues.vehicleManufactureYearMin, getYear(Date.now())).map(year => ({
          value: year.toString(),
          label: year.toString(),
        }))}
        value={ctx.state.vehicleManufactureYear.toString()}
      />
      <TextInput
        className="on-dark-bg"
        error={
          vehicleYearlyMileage.error ? (
            <T
              data={{
                min: ctx.initialValues.vehicleYearlyMileageMin,
                max: ctx.initialValues.vehicleYearlyMileageMax,
              }}
              id={vehicleYearlyMileage.error}
            />
          ) : undefined
        }
        label={<T id="calculator.vehicleYearlyMileage.label" />}
        max={ctx.initialValues.vehicleYearlyMileageMax}
        min={ctx.initialValues.vehicleYearlyMileageMin}
        onChange={it => setVehicleYearlyMileage(Number(it))}
        type="number"
        value={vehicleYearlyMileage.value.toString()}
      />
      <div className={styles.expandedForm}>
        <T as="p" className={styles.notice} id="calculator.estimatesNotice" multiline />

        <div className={styles.section}>
          <T as="h3" className={styles.sectionTitle} id="calculator.fuelCostsSection.title" />

          <TextInput
            className="on-dark-bg"
            hint={<T context={hintCtx} id="calculator.fuelCosts.hint" />}
            label={<T id="calculator.fuelCosts.label" />}
            onChange={it => expenses.setState({ fuelCosts: Number(it) })}
            type="number"
            value={expenses.state.fuelCosts.toString()}
          />
        </div>

        <div className={styles.section}>
          <T as="h3" className={styles.sectionTitle} id="calculator.operatingCostsSection.title" />

          <TextInput
            className="on-dark-bg"
            hint={<T context={hintCtx} id="calculator.depreciationCosts.hint" />}
            label={<T id="calculator.depreciationCosts.label" />}
            onChange={it => expenses.setState({ depreciationCosts: Number(it) })}
            type="number"
            value={expenses.state.depreciationCosts.toString()}
          />

          <TextInput
            className="on-dark-bg"
            hint={<T context={hintCtx} id="calculator.maintenanceCosts.hint" />}
            label={<T id="calculator.maintenanceCosts.label" />}
            onChange={it => expenses.setState({ maintenanceCosts: Number(it) })}
            type="number"
            value={expenses.state.maintenanceCosts.toString()}
          />

          <TextInput
            className="on-dark-bg"
            hint={<T context={hintCtx} id="calculator.insuranceCosts.hint" />}
            label={<T id="calculator.insuranceCosts.label" />}
            onChange={it => expenses.setState({ insuranceCosts: Number(it) })}
            type="number"
            value={expenses.state.insuranceCosts.toString()}
          />

          <TextInput
            className="on-dark-bg"
            hint={<T context={hintCtx} id="calculator.repairsCosts.hint" />}
            label={<T id="calculator.repairsCosts.label" />}
            onChange={it => expenses.setState({ repairsCosts: Number(it) })}
            type="number"
            value={expenses.state.repairsCosts.toString()}
          />

          <TextInput
            className="on-dark-bg"
            hint={<T context={hintCtx} id="calculator.administrativeCosts.hint" />}
            label={<T id="calculator.administrativeCosts.label" />}
            onChange={it => expenses.setState({ administrativeCosts: Number(it) })}
            type="number"
            value={expenses.state.administrativeCosts.toString()}
          />

          <TextInput
            className="on-dark-bg"
            hint={<T context={hintCtx} id="calculator.otherCosts.hint" />}
            label={<T id="calculator.otherCosts.label" />}
            onChange={it => expenses.setState({ otherCosts: Number(it) })}
            type="number"
            value={expenses.state.otherCosts.toString()}
          />
        </div>

        <Button className={styles.shrinkFormButton} onClick={handleShrinkForm} variant="link">
          <T as="span" id="calculator.shrinkForm" />
          <i className="icon icon-arrow-down icon-flip-x" />
        </Button>
      </div>
      <Button className={styles.expandFormButton} onClick={handleExpandForm} variant="link">
        <T as="span" id="calculator.expandForm" />
        <i className="icon icon-arrow-right" />
      </Button>
    </div>
  );
}

function SimpleCalculatorResults() {
  const vehicleEarnings = useVehicleEarnings();

  return (
    <div className={styles.calculatorResult}>
      <T as="h4" className={styles.label} id="calculator.earnings.title" />
      <Price as="h2" className={styles.price} price={vehicleEarnings} />
      <T as="span" className={styles.unit} id="calculator.earnings.perYear" />
    </div>
  );
}

function AdvancedCalculatorResults() {
  const service = useCalculatorService();
  const vehicleEarnings = useVehicleEarnings();
  const vehicleExpenses = useVehicleExpenses();
  const expensesCoverage = service.calculateExpenseCoverage(vehicleExpenses.amount, vehicleEarnings.amount);

  return (
    <>
      <div className={styles.calculatorResult}>
        <T as="h4" className={styles.label} id="calculator.earnings.title" />
        <div className={styles.priceWrapper}>
          <Price as="h2" className={styles.price} price={vehicleEarnings} />
          <T as="span" className={styles.unit} id="calculator.earnings.perYear" />
        </div>
      </div>
      <div className={styles.calculatorResult}>
        <T as="h4" className={styles.label} id="calculator.expenses.title" />
        <div className={styles.priceWrapper}>
          <Price as="h2" className={styles.price} fractions={[0, 1]} price={vehicleExpenses} />
          <T as="span" className={styles.unit} id="calculator.earnings.perYear" />
        </div>
      </div>
      <div className={styles.percentageResult}>
        <NumberFormat
          as="span"
          className={styles.percentage}
          fractions={[0, 1]}
          suffix={' %'}
          value={expensesCoverage}
        />
        <T as="span" id="calculator.coverage" />
      </div>
      <T as="p" className={styles.description} id="calculator.results.notice" multiline />
    </>
  );
}
