import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModalEnum } from '../../ModalEnum';
import { isUserInVerification, isUserVerified } from '../../model/User';
import RouteEnum from '../../RouteEnum';
import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import { openModal } from '../../store/app';
import { StaticImage } from '../Image';
import Link from '../Link';
import T, { TContext } from '../Translate';

import styles from './journeyBlock.module.scss';

interface Props {
  page: 'homepage' | 'offer_car';
}

type LinkType = {
  to: RouteEnum;
  private?: boolean;
  modal?: ModalEnum;
};

interface Links {
  link_1: LinkType;
  link_2: LinkType;
  link_3: RouteEnum | 'hidden';
}

const linksMapping: Record<
  Props['page'],
  Record<'guest' | 'in_registration' | 'in_verification' | 'verified', Links>
> = {
  homepage: {
    guest: {
      link_1: { to: RouteEnum.SIGN_UP, modal: ModalEnum.SIGN_UP },
      link_2: { to: RouteEnum.RENT_CAR },
      link_3: RouteEnum.RENT_CAR,
    },
    in_registration: {
      link_1: { to: RouteEnum.USER_REGISTRATION },
      link_2: { to: RouteEnum.RENT_CAR },
      link_3: RouteEnum.RENT_CAR,
    },
    in_verification: {
      link_1: null,
      link_2: { to: RouteEnum.RENT_CAR },
      link_3: RouteEnum.RENT_CAR,
    },
    verified: {
      link_1: null,
      link_2: null,
      link_3: RouteEnum.RENT_CAR,
    },
  },

  offer_car: {
    guest: {
      link_1: { to: RouteEnum.SIGN_UP, modal: ModalEnum.SIGN_UP },
      link_2: { to: RouteEnum.ADD_CAR, private: true },
      link_3: 'hidden',
    },
    in_registration: {
      link_1: { to: RouteEnum.USER_REGISTRATION },
      link_2: { to: RouteEnum.USER_REGISTRATION },
      link_3: 'hidden',
    },
    in_verification: {
      link_1: null,
      link_2: { to: RouteEnum.ADD_CAR },
      link_3: 'hidden',
    },
    verified: {
      link_1: null,
      link_2: null,
      link_3: 'hidden',
    },
  },
};

export function JourneyBlock(props: Props) {
  const dispatch = useDispatch();
  const userStatus = useSelector((store: ReduxState) => {
    let userStatus = 'guest';
    if (store.user) {
      if (isUserVerified(store.user)) {
        userStatus = 'verified';
      } else if (isUserInVerification(store.user)) {
        userStatus = 'in_verification';
      } else {
        userStatus = 'in_registration';
      }
    }
    return userStatus;
  });
  const links = useMemo(() => linksMapping[props.page][userStatus] as Links, [userStatus]);

  const handleLink1Click = useCallback(() => {
    Tracking.track('JOURNEY_1_BUTTON_CLICKED');
    if ('modal' in links.link_1) {
      if (userStatus === 'guest' && links.link_1.to === RouteEnum.SIGN_UP) {
        Tracking.track('JOURNEY_GUEST_SIGN_UP');
      }

      dispatch(openModal(links.link_1.modal));
      return false;
    }
  }, [links]);

  const handleLink2Click = useCallback(() => {
    Tracking.track('JOURNEY_2_BUTTON_CLICKED');
    if ('modal' in links.link_2) {
      if (userStatus === 'guest' && links.link_1.to === RouteEnum.SIGN_UP) {
        Tracking.track('JOURNEY_GUEST_SIGN_UP');
      }

      dispatch(openModal(links.link_2.modal));
      return false;
    }
  }, [links]);

  return (
    <TContext name={userStatus}>
      <div className={styles.container}>
        {links.link_1 ? (
          <div className={styles.item}>
            <div className={styles.body}>
              <div className={styles.content}>
                <T as="h2" id="journey.1.title" />
                <T as="p" id="journey.1.description" multiline />
                <Link
                  className={styles.button}
                  onClick={handleLink1Click}
                  privatePath={links.link_1.private}
                  to={links.link_1.to}
                >
                  <T id="journey.1.link" />
                  <i className="icon icon-arrow-right-circle" />
                </Link>
              </div>
              <StaticImage alt="" className={styles.image} loading="lazy" name="journey_1" />
            </div>
          </div>
        ) : null}

        {links.link_2 ? (
          <div className={styles.item}>
            <div className={styles.body}>
              <div className={styles.content}>
                <T as="h2" id="journey.2.title" />
                <T as="p" id="journey.2.description" multiline />
                <Link
                  className={styles.button}
                  onClick={handleLink2Click}
                  privatePath={links.link_2.private}
                  to={links.link_2.to}
                >
                  <T id="journey.2.link" />
                  <i className="icon icon-arrow-right-circle" />
                </Link>
              </div>
              <StaticImage alt="" className={styles.image} loading="lazy" name="journey_2" />
            </div>
          </div>
        ) : null}

        {links.link_3 ? (
          <div className={styles.item}>
            <div className={styles.body}>
              <div className={styles.content}>
                <T as="h2" id="journey.3.title" />
                <T as="p" id="journey.3.description" multiline />
                {links.link_3 !== 'hidden' ? (
                  <div className={styles.buttonHint}>
                    <Link
                      className={styles.button}
                      onClick={() => Tracking.track('JOURNEY_3_BUTTON_CLICKED')}
                      to={links.link_3}
                    >
                      <T id="journey.3.link" />
                      <i className="icon icon-arrow-right-circle" />
                    </Link>
                    <T id="journey.3.link.hint" />
                  </div>
                ) : null}
              </div>
              <StaticImage alt="" className={styles.image} loading="lazy" name="journey_3" />
            </div>
          </div>
        ) : null}
      </div>
    </TContext>
  );
}
